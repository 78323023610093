@import "../../styles/variables.scss";

.instruction-item-container {
  background: $color-white;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 360px;
  cursor: default;
  position: relative;
  overflow: hidden;
  align-self: stretch;
  .instruction-item-text {
    z-index: 2;
    font-weight: 400;
    font-size: $font-size;
    font-family: FiraGo, serif;
    color: $color-dark;
  }
  > svg {
    z-index: 2;
  }
  .instruction-background-steps {
    position: absolute;
    bottom: 0;
    right: 16px;
    font-weight: 300;
    font-size: 182px;
    color: #f9f9f9;
    z-index: 1;
    max-height: 206px;
    font-family: Helvetica, serif;
  }
}

@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.description-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 40px;
  min-height: calc(100vh - 116px);
  .description-container-inner {
    max-width: 1160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 57px;
    width: 100%;

    .description-left-wrapper {
      max-width: 496px;
      position: relative;
      z-index: 1;

      .description-heading {
        margin-bottom: 48px;
      }

      .description-sub-text {
        font-size: $font-size-lg;
        font-weight: 400;
        font-family: $geo-font-secondary;

        &.one {
          margin-bottom: 48px;
        }

        &.two {
          margin-bottom: 48px;
        }
      }

      .download-app-wrapper {
        display: flex;
        align-items: center;
        gap: 72px;

        .download-app-item-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          cursor: pointer;

          .download-app-item-text-wrapper {
            .download-app-item-text {
              font-family: $geo-font-medium;
              font-weight: 500;
              font-size: $font-size-sm;
              margin-top: 5px;
              color: #242424;
            }
          }

          > svg {
            flex-shrink: 0;
          }
        }
      }
    }

    .description-right-wrapper {
      flex-shrink: 0;
      .hand-image {
        height: 600px;
      }
    }
  }
}

@include xl {
  .description-container {
    .description-container-inner {
      .description-left-wrapper {
        .description-heading {
          font-size: 32px;
        }
      }
    }
  }
}

@include lg {
  .description-container {
    margin-top: 84px;
    .description-container-inner {
      flex-direction: column;
      .description-left-wrapper {
        max-width: unset;
        .description-heading {
          font-size: 26px;
        }
      }
      .description-right-wrapper {
        .hand-image {
          height: 400px;
        }
      }
    }
  }
}

@include sm {
  .description-container {
    .description-container-inner {
      .description-left-wrapper {
        .description-heading {
          margin-bottom: 24px;
        }
        .download-app-wrapper {
          justify-content: space-between;
          gap: 20px;
          .download-app-item-wrapper {
            flex-direction: column;
            .download-app-item-text-wrapper {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@import "../../../../styles/variables.scss";
@import "../../../../styles/responsive.scss";

.edit-profile-modal {
  max-width: 800px !important;
  width: 100% !important;
  .ant-modal-content {
    .ant-modal-body {
      padding: 40px;
      .edit-profile-form {
        .edit-profile-form-content {
          display: flex;
          gap: 40px;
          .avatar-wrapper {
            flex-shrink: 0;
            width: 204px;
            height: 204px;
            position: relative;

            .avatar-img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }

            .edit-avatar-form-item {
              position: absolute;
              bottom: 0;
              right: 0;

              .edit-profile-icon-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 64px;
                width: 64px;
                border-radius: 50%;
                background: $color-white;
                box-shadow:
                  0 25px 35px 0 rgba(0, 0, 0, 0.03),
                  0 0 5px 0 rgba(0, 0, 0, 0.05);
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

@include md {
  .edit-profile-modal {
    .ant-modal-content {
      .ant-modal-body {
        .edit-profile-form {
          .edit-profile-form-content {
            flex-direction: column;
            align-items: center;
            gap: 24px;
            .avatar-wrapper {
              width: 81px;
              height: 81px;
              .edit-avatar-form-item {
                .edit-profile-icon-wrapper {
                  height: 32px;
                  width: 32px;
                  svg {
                    height: 12px;
                    width: 12px;
                  }
                }
              }
            }
            .custom-button {
              height: 56px;
              .button-text {
                font-size: $font-size-sm;
              }
            }
          }
        }
      }
    }
  }
}

@import "../../../../styles/variables.scss";

.menu-item-container {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: $geo-font-medium;
  color: $color-dark;
  cursor: pointer;
  .menu-item-label {
    margin-top: 5px;
  }
}

@import "../../styles/variables.scss";

.stat-item-container {
  min-width: 360px;
  height: 336px;
  background: $color-white;
  box-shadow: 0 0 20px rgba(214, 214, 214, 0.3);
  border-radius: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    transition: all 0.4s ease 0s !important;
    transform: translateY(-10px) scale(1.05) !important;
  }
  > svg {
    margin-bottom: 19px;
    flex-shrink: 0;
  }

  .stat-numbers-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .stat-num-wrapper {
      display: flex;
      align-items: center;
      font-family: $geo-font-bold;
      font-weight: 700;
      font-size: 48px;
    }

    .stat-sub-text {
      font-family: $geo-font-medium;
      font-weight: 500;
      font-size: 24px;
      color: $color-dark;
    }
  }
}

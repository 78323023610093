@import "../../../../styles/variables";
@import "../../../../styles/responsive";

.doer-instructions-wrapper {
  //margin-top: 36px;
  .doer-instructions-heading {
    font-weight: 700;
    font-size: $font-size-xxl;
    font-family: $geo-font-bold;
    margin-bottom: 20px;
    color: $color-green;
  }
  .doer-instructions-steps-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
    .instruction-item-container {
      flex: 1;
    }
  }
}

@include md {
  .doer-instructions-wrapper {
    .doer-instructions-steps-wrapper {
      flex-direction: column;
      .instruction-item-container {
        min-width: 215px;
        width: 100%;
        max-width: unset;
      }
    }
  }
}
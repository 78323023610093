@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.auth-personal-info-step-wrapper {
  //padding-top: 30px;
  //padding-bottom: 20px;
  .personal-info-step-description-text {
    color: $color-dark;
    font-size: $font-size-lg;
    margin-bottom: 16px;
    font-family: $geo-font-secondary;
  }
  .personal-info-step-fields-container {
    .personal-info-step-fields-name-wrapper,
    .personal-info-step-fields-city-wrapper {
      margin-bottom: 16px;
    }
    .agree-icon-wrapper {
      flex-shrink: 0;
      .agree-checkbox {
        align-items: center;
        .ant-checkbox span.ant-checkbox-inner {
          border-radius: 50%;
          width: 24px;
          height: 24px;

          &:after {
            left: 8px;
          }
        }

        .ant-checkbox.ant-checkbox-checked span.ant-checkbox-inner {
          background-color: $color-green;
          border: none;
        }

        .ant-checkbox.ant-checkbox-checked:after {
          border: none;
        }

        .terms-and-conditions-text-wrapper {
          font-size: $font-size-xs;
          color: $color-grey;
          font-family: $geo-font-secondary;
          .link-text {
            color: $color-primary;
            font-weight: 700;
            text-decoration: underline;
            padding-left: 5px;
            padding-right: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.custom-select-dropdown {
  &.personal-info-step-city-dropdown {
    z-index: 1001;
  }
}

@include lg {
  .auth-personal-info-step-wrapper {
    .personal-info-step-description-text {
      font-size: 15px;
    }
  }
}

@import "../../../../../styles/variables.scss";

.task-status-menu-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  .task-status-type-wrapper {
    height: 38px;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 33px;
    border: 1px solid $color-background;
    color: $color-grey;
    width: fit-content;
    cursor: pointer;
    &.selected {
      border: 1px solid $color-dark;
      color: $color-dark;
    }
    .task-status-type-indicator {
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
    .task-status-type-text {
      margin-top: 5px;
      font-family: $geo-font-medium;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

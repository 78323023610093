//COLORS
$color-primary: #ef3653;
$color-white: #ffffff;
$color-background: #f0f2f4;
$color-dark: #2c3642;
$color-green: #06d672;
$color-grey: #617585;
$color-light-grey: #d3dae1;
$color-blue: #37a6f7;
$color-blue-secondary: #b2dcfa;
$color-red: #ff3100;
$color-yellow: #F9C922;

//FONTS
$geo-font:
  TBC Contractica CAPS,
  serif;
$geo-font-medium: TBC Contractica CAPS Medium;
$geo-font-bold: TBC Contractica CAPS Bold;

$geo-font-secondary: FiraGo, serif;

//FONT SIZES
$font-size-xxs: 11px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;
$font-size-xxl: 24px;

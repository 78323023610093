@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  position: relative;
  z-index: 1;
  .main-container-inner {
    max-width: 1160px;
    display: flex;
    justify-content: space-between;
    gap: 57px;
    width: 100%;
    .main-left-wrapper {
      width: 100%;
      max-width: 496px;
      padding: 15px 0;
      .main-heading {
        margin-bottom: 20px;
        min-width: 405px;
      }

      .steps-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 20px;

        .main-input-text {
          font-family: $geo-font-secondary;
          font-weight: 400;
          font-size: $font-size-lg;
        }

        //.with-validation {
        //  .ant-form-item-explain-error {
        //    display: none;
        //  }
        //}

        .agree-terms-and-conditions-text {
          font-weight: 400;
          font-size: 12px;
          font-family: $geo-font-secondary;
          color: $color-grey;
          > span {
            margin-left: 5px;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .step-two-inputs-wrapper,
        .step-three-inputs-wrapper {
          max-height: 200px;
          height: 100%;

          display: flex;
          flex-direction: column;
          gap: 15px;

          .terms-and-conditions-wrapper {
            display: flex;
            align-items: center;
            gap: 8px;

            .terms-and-conditions-checkbox {
              .ant-checkbox-checked {
                &:after {
                  border: none;
                }

                .ant-checkbox-inner {
                  background: $color-green;
                  border-color: $color-green;
                }
              }
            }

            .terms-and-conditions-text {
              font-family: FiraGo, serif;
            }
          }
        }

        .step-two-inputs-wrapper {
        }

        .step-three-buttons-wrapper,
        .step-two-buttons-wrapper {
          display: flex;
          gap: 16px;
          justify-content: space-between;
          align-items: center;
          .back-button {
            flex-shrink: 0;
          }
        }

        .step-two-inputs-wrapper {
          .step-two-input {
            height: 64px;
            padding: 10px 24px;
            display: flex;
            align-items: center;
            font-family: $geo-font-secondary;
            font-size: $font-size-lg;
            .ant-input-prefix {
              .phone-prefix {
                color: #c1c1c1;
              }
            }
            .ant-input {
              font-family: $geo-font-secondary;
              font-size: $font-size-lg;
            }
          }
        }

        .success-step-wrapper {
          display: flex;
          flex-direction: column;
          min-height: 200px;
          align-items: center;
          gap: 25px;
          justify-content: center;
          flex: 1;

          > svg {
            flex-shrink: 0;
          }

          .success-step-text {
            font-weight: 700;
            font-size: $font-size-xl;
            font-family: $geo-font-bold;
            color: $color-green;
            max-width: 360px;
            margin-top: 3px;
            text-align: center;
          }
          .error-step-text {
            font-weight: 700;
            font-size: $font-size-xl;
            font-family: $geo-font-bold;
            color: $color-red;
            max-width: 360px;
            margin-top: 3px;
            text-align: center;
          }
        }
        .success-step-button {
          background: #d3dae1;
          .button-text {
            color: $color-grey;
          }
        }
      }
    }

    .main-right-wrapper {
      width: 51%;
      .slick-list {
        border-radius: 40px;
        .main-carousel-item {
          height: 600px;
          object-fit: cover;
        }
      }
    }
  }
}

@include xl {
  .main-container {
    .main-container-inner {
      .main-left-wrapper {
        .main-heading {
          font-size: 32px;
        }
      }
    }
  }
}

@include lg {
  .main-container {
    margin-top: 40px;
    height: auto;
    .main-container-inner {
      flex-wrap: wrap-reverse;
      gap: 23px;
      .main-left-wrapper {
        max-width: unset;
        width: 100%;
        .main-heading {
          font-family: $geo-font-secondary;
          font-weight: 400;
          font-size: $font-size-lg;
          color: $color-dark;
          min-width: unset;
        }
        .steps-form {
          .main-textarea {
            height: 140px;
          }
          .success-step-wrapper {
            flex-direction: column;

            .success-step-text {
              text-align: center;
            }
          }
        }
      }
      .main-right-wrapper {
        width: 100%;
        position: relative;
        //&::after {
        //  content: "";
        //  position: absolute;
        //  left: 0;
        //  top: 0;
        //  width: 100%;
        //  height: 100%;
        //  background: linear-gradient(
        //    180deg,
        //    rgba(44, 54, 66, 0) 0%,
        //    #2c3642 100%
        //  );
        //  border-radius: 40px;
        //}
        .slick-list {
          .main-carousel-item {
            height: 340px;
          }
        }
        .carousel-main-heading {
          position: absolute;
          color: $color-white;
          bottom: 25px;
          left: 20px;
          font-weight: 700;
          font-size: $font-size-xxl;
          margin-bottom: 0;
          z-index: 1;
          padding-right: 20px;
        }
      }
    }
  }
}

@import "../../../../styles/variables.scss";
.user-menu {
  border-radius: 8px;
  background: $color-white;
  box-shadow:
    0 25px 35px 0 rgba(0, 0, 0, 0.03),
    0 0 5px 0 rgba(0, 0, 0, 0.05);
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  .user-menu-item {
    padding: 12px 20px;
    //.ant-dropdown-menu-title-content {
    //  display: flex;
    //  align-items: center;
    //  gap: 12px;
    //  font-family: $geo-font-medium;
    //  color: $color-dark;
    //  .menu-item-label {
    //    margin-top: 5px;
    //  }
    //}
  }
}

@import "../../styles/variables.scss";

.payment-status-modal {
  .payment-status-modal-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 400px;
    .payment-status-modal-content {
      max-width: 275px;
      .payment-status-logo {
        text-align: center;
      }
      .payment-status-text {
        margin-top: 30px;
        text-align: center;
        font-size: $font-size;
        font-family: $geo-font-bold;
      }
      .payment-status-subtext {
        margin-top: 30px;
        text-align: center;
        font-size: $font-size-sm;
        font-family: $geo-font-secondary;
      }
    }
  }
  .payment-status-loading-wrapper {
    margin-bottom: 125px;
  }
  .payment-status-footer-button-wrapper {
    border-top: 1px solid #f0f0f0;
    padding: 20px 40px 32px;
  }
}
@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.custom-textarea {
  border-radius: 16px;
  border: 1px solid #d3dae1;
  padding: 20px 24px;
  resize: none;
  &.ant-input {
    transition:
      border-color 0.3s,
      height 0s;
    height: 167px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: $font-size-lg;
    font-family: $geo-font-secondary;
  }
}

//.ant-form-item-has-success {
//  .custom-textarea {
//    border: 1px solid $color-green;
//    &:focus {
//      box-shadow: 0 0 0 2px rgba(6, 214, 114, 0.2);
//      border: 1px solid $color-green;
//    }
//  }
//}

@include lg {
  .custom-textarea {
    border-radius: 8px;
    &.ant-input {
      font-size: 15px;
    }
  }
}

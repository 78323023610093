@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.custom-modal {
  .ant-modal-content {
    border-radius: 16px;
    margin: 0 15px;
    .ant-modal-close {
      display: none;
    }
    .ant-modal-header {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding: 32px 40px 20px;
      .custom-modal-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .custom-modal-title-text {
          margin-top: 5px;
          font-weight: 500;
          font-size: $font-size-lg;
          color: $color-dark;
          font-family: $geo-font-medium;
        }
        .custom-modal-close-icon {
          cursor: pointer;
        }
      }
    }
    .ant-modal-body {
      //padding: 0 40px;
      padding: 0;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #a0acba;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-track {
        background: rgba(104, 122, 158, 0.25);
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgba(104, 122, 158, 0.5);
      }
    }
    .ant-modal-footer {
      //border-top: none;
      padding: 20px 40px 32px;
    }
  }
}

@include lg {
  .custom-modal {
    .ant-modal-content {
      .ant-modal-header {
        padding: 20px 16px 16px;
        .custom-modal-title-wrapper {
          .custom-modal-title-text {
            font-size: $font-size;
          }
        }
      }
    }
  }
}

@import "../../styles/variables.scss";

.language-select {
  &.ant-select {
    //width: 100%;
    //width: 118px;
    .ant-select-selector {
      border-radius: 12px;
      padding: 16px;
      height: 52px;
      border: 1px solid $color-light-grey;
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        font-size: $font-size-sm;
        font-family: $geo-font-secondary;
        font-weight: 500;
        color: $color-grey;
      }
      .language-option-content {
        display: flex;
        align-items: center;
        margin-right: 7px;
      }
    }
    .ant-select-arrow {
      right: 20px;
    }
  }
}

.language-select-dropdown {
  &.ant-select-dropdown {
    border-radius: 8px;
    z-index: 3;
    //width: 100%;
    width: auto !important;
    background: #f9f9f9;

    .language-select-option {
      &.ant-select-item {
        height: 42px;
        display: flex;
        align-items: center;
        font-size: $font-size-sm;
        font-family: $geo-font-secondary;
        color: $color-grey;
        &.ant-select-item-option-selected {
          display: none;
        }
        .language-option-content {
          display: flex;
          align-items: center;
          gap: 10px;
          > svg {
            width: 20px;
          }
        }
      }
    }
  }
}

@import "../../styles/variables.scss";

.validation-text-wrapper {
  display: flex;
  align-items: center;
  //margin-bottom: 5px;
  //position: absolute;
  .validation-text {
    font-family: $geo-font-secondary;
    margin-top: 5px;
    margin-left: 5px;
    color: $color-red;
  }
}

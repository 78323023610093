@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.how-wempler-works-container {
  margin-top: 92px;
  margin-bottom: 92px;
  display: flex;
  justify-content: center;
  padding: 0 40px;
  .how-wempler-works-wrapper {
    max-width: 1160px;
    flex: 1;
    .how-it-works-heading {
      color: $color-dark;
    }
  }
}

@include xl {
  .how-wempler-works-container {
    margin-top: 80px;
    .how-wempler-works-wrapper {
      .how-it-works-heading {
        font-size: 32px;
      }
    }
  }
}
.edit-profile-drawer {
  .ant-drawer-close {
    display: none;
  }
  .edit-profile-drawer-items-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 24px;
  }
}

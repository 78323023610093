@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-primary;
  border-radius: 12px;
  height: 72px;
  border: none;
  gap: 12px;
  cursor: pointer;
  width: 100%;
  transition: 0.5s;
  &:hover {
    opacity: 0.7;
    background: $color-primary;
  }
  &:focus {
    background: $color-primary;
  }
  &.ant-btn-loading {
    cursor: not-allowed;
  }
  &[disabled] {
    opacity: 0.7;
    background: $color-primary;
    &:hover {
      opacity: 0.7;
      background: $color-primary;
    }
  }
  > span:not(.ant-btn-loading-icon) {
    display: none;
  }
  .anticon-loading {
    > svg {
      fill: $color-white;
      width: 20px;
      height: 20px;
    }
  }
  .button-text {
    font-family: $geo-font;
    color: $color-white;
    font-weight: 700;
    font-size: $font-size-lg;
    margin-top: 5px;
  }
}

@include lg {
  .custom-button {
    height: 56px;
    .button-text {
      font-size: $font-size-sm;
    }
  }
}

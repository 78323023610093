@import "../../styles/variables.scss";

.review-item-container {
  max-width: 410px;
  background: $color-white;
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.08);
  border-radius: 76px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px;
  cursor: pointer;
  &:hover {
    transition: all 0.2s ease 0s !important;
    transform: translateY(-10px) scale(1.05) !important;
  }
  .review-image {
    width: 68px;
    height: 68px;
  }
  .review-text-wrapper {
    .reviewer-data {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      .reviewer-name {
        font-family: $geo-font-bold;
        font-weight: 700;
      }
      .reviewer-info {
        font-weight: 400;
      }
    }
    .review-text {
      font-family:
        Noto Sans,
        Georgian,
        serif;
      font-weight: 400;
      font-size: 14px;
      color: $color-dark;
    }
  }
}

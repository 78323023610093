.tasks-drawer {
  //.ant-drawer-close {
  //  display: none;
  //}
  .task-items-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

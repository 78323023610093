@import "../../styles/variables.scss";

.section-heading {
  font-family: $geo-font-bold;
  font-weight: 700;
  font-size: 40px;
  color: $color-primary;
  margin-bottom: 0;
  line-height: 1.2;
}

@import "../../styles/variables.scss";

.custom-drawer {
  .ant-drawer-content-wrapper {
    height: unset !important;
    .ant-drawer-content {
      border-radius: 20px 20px 0 0;
      .ant-drawer-header {
        padding: 20px 24px 16px;
      }
      .custom-drawer-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-text {
          margin-top: 5px;
          color: $color-dark;
          font-family: $geo-font-bold;
          font-size: $font-size-xl;
        }
        .drawer-close-icon {
          cursor: pointer;
        }
      }
    }
  }
}

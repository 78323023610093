@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.custom-select {
  &.ant-select {
    width: 100%;
    .ant-select-selector {
      border-radius: 16px;
      padding: 0 20px;
      height: 64px;
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        font-size: $font-size;
        font-family: $geo-font-secondary;
        font-weight: 500;
      }
    }
    .ant-select-arrow {
      right: 20px;
    }
  }
}

.custom-select-dropdown {
  &.ant-select-dropdown {
    border-radius: 8px;
    z-index: 2;

    .ant-select-item {
      height: 42px;
      display: flex;
      align-items: center;
      font-size: $font-size;
    }
  }
}

@include lg {
  .custom-select {
    &.ant-select {
      .ant-select-selector {
        border-radius: 8px;
        height: 44px;
        padding: 0 10px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          font-size: 15px;
        }
      }
    }
  }

  .custom-select-dropdown {
    &.ant-select-dropdown {
      .ant-select-item {
        font-size: 15px;
      }
    }
  }
}

@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.user-container {
  .authorization-button {
    height: 52px;
    .button-text {
      font-size: $font-size-sm;
    }
  }
  .avatar-wrapper {
    height: 52px;
    width: 52px;
    cursor: pointer;
    .avatar-img {
      height: 100%;
      width: 100%;
      border: 1px solid $color-blue-secondary;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

@include xl {
  .user-container {
    .authorization-button {
      height: 52px;
      width: 52px;
      .button-text {
        display: none;
      }
    }
    .avatar-wrapper {
      height: 52px;
      width: 52px;
    }
  }
}

@include lg {
  .user-container {
    .authorization-button {
      height: 42px;
      width: 42px;
      .button-text {
        display: none;
      }
    }
    .avatar-wrapper {
      height: 32px;
      width: 32px;
    }
  }
}

.custom-carousel-wrapper {
  max-width: 679px;
  height: 559px;
  border-radius: 20px;
  animation: fadeIn 3000ms infinite;
  .carousel-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    animation: fadeIn 3000ms infinite;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

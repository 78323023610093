@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.promotion-modal {
  width: 691px !important;
  .promotion-content-wrapper {
    display: flex;
    justify-content: space-between;
    height: 576px;
    .promotion-banner-image-wrapper {
      width: 40%;
      height: 100%;
      .promotion-banner-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px 0 0 16px;
      }
    }
    .promotion-text-container {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      border-radius: 0 16px 16px 0;
      position: relative;
      .promotion-modal-close {
        position: absolute;
        top: 15px;
        right: 15px;
        background: rgba(255, 255, 255, 0.70);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 29px;
        height: 29px;
        cursor: pointer;
      }
      .promotion-text-wrapper {
        text-align: center;
        .promotion-company-image {
          width: 90%;
        }
        .promotion-company-title {
          font-family: $geo-font-bold;
          font-size: $font-size-xxl;
          color: $color-white;
          margin: 16px 0;
        }
        .promotion-company-description {
          font-family: $geo-font;
          font-size: $font-size-sm;
          font-weight: 400;
          color: $color-white;
        }
      }
    }
  }
}

@include sm {
  .promotion-modal {
    .promotion-content-wrapper {
      .promotion-text-container {
        .promotion-text-wrapper {
          .promotion-company-title {
            font-size: $font-size-lg;
          }
          .promotion-company-description {
            font-size: 10px;
          }
        }
      }
    }
  }
}
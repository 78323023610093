@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.custom-input {
  height: 64px;
  border-radius: 16px;
  border: 1px solid #d3dae1;
  padding: 18px;
  .ant-input,
  .ant-input-prefix,
  &.ant-input {
    font-size: $font-size;
    font-family: $geo-font-secondary;
  }
  //&.ant-input {
  //  transition:
  //    border-color 0.3s,
  //    height 0s;
  //  height: 64px;
  //  font-size: $font-size-lg;
  //  font-family: $geo-font-secondary;
  //  font-weight: 500;
  //  &[type="number"] {
  //    &::-webkit-inner-spin-button,
  //    &::-webkit-outer-spin-button {
  //      -webkit-appearance: none;
  //      margin: 0;
  //    }
  //  }
  //}
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

//.ant-form-item-has-success {
//  .custom-input {
//    border: 1px solid $color-green;
//    &:focus {
//      box-shadow: 0 0 0 2px rgba(6, 214, 114, 0.2);
//      border: 1px solid $color-green;
//    }
//  }
//}

.ant-form-item-explain {
  margin-top: 5px;
}
.ant-form-item-explain-error {
  //position: absolute;
}

@include lg {
  .custom-input {
    height: 44px;
    padding: 10px;
    border-radius: 8px;
    .ant-input,
    .ant-input-prefix,
    &.ant-input {
      font-size: 15px;
    }
  }
}

@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 26px 26px;
  background: #80919f;
  .footer-container-inner {
    max-width: 1200px;
    width: 100%;
    .footer-main-section-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 64px;
      .footer-main-text-wrapper {
        text-align: center;
        .footer-main-text {
          color: $color-white;
          font-weight: 700;
          font-size: $font-size-xxl;
          margin-bottom: 6px;
        }
        .footer-main-subtext {
          color: #d3dae1;
          font-weight: 400;
          font-size: $font-size-sm;
          font-family: $geo-font-secondary;
        }
      }
    }
    .footer-info-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .footer-contact-info-wrapper {
        display: flex;
        align-items: center;
        gap: 64px;
        .footer-info-item-wrapper {
          display: flex;
          align-items: center;
          gap: 12px;

          > svg {
            flex-shrink: 0;
          }

          .footer-item-description-wrapper {
            .footer-item-title {
              color: #d3dae1;
              font-weight: 600;
              font-size: $font-size-sm;
              font-family: $geo-font-secondary;
              margin-bottom: 3px;
            }

            .footer-item-subtitle {
              color: $color-white;
              font-weight: 400;
              font-size: $font-size-xs;
              text-decoration: underline;
              font-family: $geo-font-secondary;
            }
          }
        }
      }
    }
    .footer-social-media-wrapper {
      display: flex;
      align-items: center;
      padding: 10px 10px 10px 32px;
      border-left: 1px solid #a0acba;
      gap: 23px;
      &.responsive {
        display: none;
      }
      .social-media-item {
        height: 25px;
      }
    }
  }
}

.credentials-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px;
  background: #a0acba;
  .credentials-container-inner {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    > svg {
      flex-shrink: 0;
    }
    .credentials-right-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 42px;
      .terms-and-conditions-text,
      .privacy-policy-text,
      .cancellation-policy-text,
      .faq-text {
        font-weight: 400;
        font-size: $font-size-sm;
        color: $color-white;
        text-decoration: underline;
        font-family: $geo-font-secondary;
        cursor: pointer;
        text-align: center;
      }
      .website-text {
        font-weight: 500;
        font-family: $geo-font-medium;
        font-size: 12px;
        margin-top: 10px;
        color: $color-white;
      }
    }
  }
}

@include lg {
  .footer-container {
    .footer-container-inner {
      .footer-main-section-wrapper {
        justify-content: space-between;
        gap: 20px;
        .footer-main-text-wrapper {
          text-align: start;
        }
      }
      .footer-social-media-wrapper {
        &.responsive {
          display: flex;
          align-self: stretch;
        }
        display: none;
      }
    }
  }
}

@include md {
  .footer-container {
    .footer-container-inner {
      .footer-main-section-wrapper {
        justify-content: flex-start;
        margin-bottom: 32px;
        .footer-main-text-wrapper {
          text-align: start;
        }
      }
      .footer-info-wrapper {
        flex-direction: column;
        align-items: flex-start;
        .footer-contact-info-wrapper {
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
        }
      }
      .footer-social-media-wrapper {
        padding-left: unset;
        border-left: unset;
        margin-top: 10px;
        border-top: 1px solid $color-white;
        padding-top: 26px;
        width: 100%;
        &.responsive {
          display: none;
        }
        display: flex;
      }
    }
  }
  .credentials-container {
    .credentials-container-inner {
      flex-direction: column;
      align-items: flex-start;
      .credentials-right-container {
        gap: 15px;
        flex-direction: column;
        align-items: flex-start;
        .terms-and-conditions-text {
          text-align: center;
        }
      }
    }
  }
}

@import "../../../../styles/variables.scss";

.tasks-modal {
  max-width: 800px !important;
  width: 100% !important;
  .ant-modal-content {
    .ant-modal-body {
      padding: 28px 40px 40px;

      //.task-items-wrapper {
      //  display: flex;
      //  flex-direction: column;
      //  gap: 12px;
      //}
    }
  }
}

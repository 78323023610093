@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.faq-modal {
  max-width: 766px !important;
  width: 100% !important;
  .ant-modal-content {
    .ant-modal-body {
      max-height: 65vh;
      padding: 0 40px;
      overflow-y: scroll;
      margin: 20px 20px 20px 0;
      .bold {
        font-weight: 500;
      }
      .underlined {
        text-decoration: underline;
      }
      .section {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        > div {
          flex: 1;
        }
      }
      .divider {
        margin-bottom: 20px;
      }
      .heading {
        font-size: 18px;
        text-align: center;
      }
      .ordered-list {
        padding-left: 15px;
        .ordered-list-item {
          margin-bottom: 10px;
        }
      }
      .unordered-list {
        padding-left: 15px;
        .unordered-list-item {
          margin-bottom: 10px;
          font-size: 16px;
        }
      }
    }
    .ant-modal-footer {
      height: 30px;
    }
  }
}

@include sm {
  .faq-modal {
    &.custom-modal {
      .ant-modal-header {
        padding-bottom: 20px;
      }
    }
    .ant-modal-content {
      .ant-modal-body {
        max-height: 55vh;
        margin-right: 15px;
        .section {
          flex-direction: column;
        }
      }
    }
  }
}

@include sm-height {
  .faq-modal {
    .ant-modal-content {
      .ant-modal-body {
        max-height: 45vh;
      }
    }
  }
}

@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.auth-otp-step-wrapper {
  //padding-top: 80px;
  //padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .otp-code-sent-text {
    font-size: $font-size-lg;
    font-family: $geo-font-secondary;
    margin-bottom: 27px;
    text-align: center;
  }
  .otp-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .otp-input {
      border: none;
      width: 42px !important;
      height: 44px;
      background: $color-background;
      border-radius: 8px;
      color: $color-dark;
      font-weight: 700;
      font-size: $font-size-lg;
      &.otp-input-focus {
        outline: none;
      }
      &.otp-input-error {
        border: 1px solid $color-red;
      }
    }
  }

  .otp-input-error-text {
    margin-top: 27px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: $font-size-sm;
    color: $color-red;
  }

  .timer-wrapper {
    margin-top: 27px;
    margin-bottom: 20px;
    font-family: $geo-font;
    font-weight: 400;
    font-size: $font-size-sm;
    color: $color-grey;
  }

  .send-new-code-wrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }
    .send-new-code-text {
      font-weight: 600;
      font-size: $font-size-sm;
      font-family: $geo-font-secondary;
      color: $color-primary;
    }
  }
}

@include lg {
  .auth-otp-step-wrapper {
    .otp-code-sent-text {
      font-size: $font-size;
    }
  }
}

@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.auth-contact-info-step-wrapper {
  //padding-top: 68px;
  //padding-bottom: 68px;
  .personal-info-step-greeting {
    color: $color-primary;
    font-size: 32px;
    font-family: $geo-font-bold;
    margin-bottom: 20px;
    line-height: 44px;
  }
  .contact-info-step-description-text {
    color: $color-dark;
    font-size: $font-size-lg;
    margin-bottom: 20px;
    font-family: $geo-font-secondary;
  }
  .auth-contact-info-step-input {
    .phone-prefix {
      color: #c1c1c1;
    }
  }
}

@include lg {
  .auth-contact-info-step-wrapper {
    .personal-info-step-greeting {
      font-size: $font-size-lg;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .contact-info-step-description-text {
      font-size: $font-size;
      margin-bottom: 16px;
    }
  }
}

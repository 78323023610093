@import "../../../styles/variables";
@import "../../../styles/responsive";

.contact-container {
  margin-top: 87px;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  .contact-container-wrapper {
    background: $color-white;
    border-radius: 48px;
    box-shadow: 0 0 20px rgba(214, 214, 214, 0.3);
    padding: 66px 26px 66px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1220px;
    flex: 1;
    gap: 30px;
    .contact-left-wrapper {
      max-width: 552px;
      flex: 1;
      .contact-heading {
        margin-bottom: 20px;
      }
      .contact-sub-text {
        font-size: $font-size-lg;
        font-weight: 400;
        max-width: 504px;
        margin-bottom: 48px;
      }
      .contact-button {
        max-width: 295px;
      }
    }
    .contact-right-wrapper {
      align-self: stretch;
      flex: 1;
    }
  }
}

@include lg {
  .contact-container {
    .contact-container-wrapper {
      flex-direction: column;
      max-width: unset;
      padding: 40px;
      .contact-left-wrapper {
        max-width: unset;
        .contact-heading {
          font-size: 34px;
        }
      }
    }
  }
}

@include sm {
  .contact-container {
    margin-top: 20px;
    .contact-container-wrapper {
      .contact-left-wrapper {
        .contact-heading {
          font-size: 28px;
        }
      }
    }
  }
}

@import "../../../styles/responsive";

.stat-container {
  margin-top: 92px;
  margin-bottom: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

@include md {
  .stat-container {
    flex-direction: column;
    .stat-item-container {
      width: 100%;
      min-width: 295px;
      flex-direction: row;
      height: 144px;
      padding: 34px;
      justify-content: flex-start;
      gap: 16px;
      > svg {
        margin-bottom: unset;
      }
      .stat-numbers-container {
        align-items: flex-start;
        .stat-num-wrapper {
          font-size: 32px;
        }
        .stat-sub-text {
          font-size: 17px;
        }
      }
    }
  }
}

.tasks-content-container {
  .task-items-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 30vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #a0acba;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(104, 122, 158, 0.25);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(104, 122, 158, 0.5);
    }
  }
}

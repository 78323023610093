@import "../../../../../styles/variables.scss";
@import "../../../../../styles/responsive.scss";

.task-item-container {
  padding: 12px 20px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 16px;
  .task-item-left-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    .task-item-icon-wrapper {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-white;
      border-radius: 50%;
      flex-shrink: 0;
    }
    .task-text-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      .task-item-text {
        margin-top: 5px;
        color: $color-dark;
        font-family: $geo-font-bold;
        font-size: 12px;
      }

      .task-item-status {
        display: flex;
        align-items: center;
        gap: 8px;

        .task-item-status-indicator {
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }

        .task-item-status-text {
          margin-top: 2px;
          color: $color-grey;
          font-family: $geo-font-secondary;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
    .task-item-insurance-wrapper {
      display: flex;
      align-items: center;
      .insurance-img {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
      .insurance-text {
        color: #64B5A6;
        font-size: 10px;
        font-weight: 600;
        font-family: $geo-font-secondary;
      }
    }
  }
  .task-item-right-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    .task-date {
      color: $color-dark;
      font-family: $geo-font-secondary;
      font-size: 12px;
      font-weight: 400;
    }
    .task-pay {
      height: 32px;
      background: $color-green;
      color: #f9f9f9;
      font-family: $geo-font-bold;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 10px;
      padding: 4px 12px;
      > span {
        margin-top: 5px;
      }
    }
  }
}

@include lg {
  .task-item-container {
    .task-item-left-wrapper {
      .task-item-icon-wrapper {
        width: 36px;
        height: 36px;
      }
      .task-text-wrapper {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        .task-item-text {
          font-size: 10px;
        }
      }
    }
    .task-item-right-wrapper {
      .task-date {
        font-size: 10px;
      }
    }
  }
}

@import "./styles/variables.scss";
@import "./styles/responsive.scss";

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: calc(
    116px + 68px
  ); /* for #anchor scroll. height of the header + custom padding */
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

@font-face {
  src: url(fonts/TBC-contractica-CAPS.ttf);
  font-family: TBC Contractica CAPS;
}

@font-face {
  src: url(fonts/TBC-contractica-CAPS-medium.ttf);
  font-family: TBC Contractica CAPS Medium;
}

@font-face {
  src: url(fonts/TBC-contractica-CAPS-bold.ttf);
  font-family: TBC Contractica CAPS Bold;
}

@font-face {
  src: url(fonts/noto-sans.ttf);
  font-family: Noto Sans;
}

@font-face {
  src: url(fonts/fira-go.ttf);
  font-family: FiraGo;
}

.App {
  font-family: $geo-font;
  font-size: $font-size;
  min-height: 100vh;
  background: $color-background;
  overflow-x: hidden;
  margin-top: 116px;
}

//disable drawer scroll
.ant-scrolling-effect {
  width: unset !important;
}

.ant-form-item {
  margin-bottom: 0;
}

@include lg {
  .App {
    margin-top: 100px;
  }
}

@import "../../../../styles/variables.scss";
@import "../../../../styles/responsive.scss";

.back-button {
  background: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  width: 72px;
  border-radius: 16px;
  border: 1px solid #d3dae1;
}

@include lg {
  .back-button {
    height: 56px;
    width: 56px;
  }
}

@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.header-container {
  height: 116px;
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 69px;
  position: fixed;
  z-index: 3;
  top: 0;
  padding: 0 40px;
  .header-container-inner {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 35px;
    .header-left-wrapper {
      display: flex;
      align-items: center;
      gap: 40px;
      .header-logo {
        flex-shrink: 0;
      }
      .header-menu-wrapper {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        gap: 28px;

        .header-menu-list-item {
          margin-top: 5px;

          &.highlighted {
            .header-menu-list-item-link {
              font-weight: 600;
              color: $color-primary;
            }
          }

          .header-menu-list-item-link {
            transition: 0.3s;
            text-decoration: none;
            color: $color-dark;
            font-family: $geo-font-medium;
            font-weight: 500;
            font-size: $font-size-sm;
            white-space: nowrap;
          }
        }
      }
    }

    .header-right-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      .contact-us-button {
        background: $color-green;
        width: 52px;
        height: 52px;
        padding: 16px;

        .button-text {
          font-size: $font-size-sm;
        }

        &:hover {
          opacity: 1;
        }
        &.full-width {
          width: 209px;
        }
      }

      .language-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        cursor: pointer;

        .language-text {
          margin-top: 5px;
          font-weight: 500;
          font-size: $font-size-sm;
          font-family: $geo-font-medium;
          color: $color-grey;
        }
      }
    }

    .drawer-wrapper {
      cursor: pointer;
    }
  }
}

.contact-us-dropdown-menu {
  background: #dbdbdb;
  border-radius: 10px;
  padding: 12px 0;
  min-width: 240px;
  .ant-dropdown-menu-item {
    color: $color-grey;
    font-weight: 700;
    font-size: $font-size-sm;
    height: 48px;
    padding: 5px 12px 5px 28px;
    font-family: $geo-font;
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
      gap: 12px;
      .dropdown-menu-text {
        margin-top: 5px;
      }
      > svg {
        > path {
          stroke: $color-grey;
        }
      }
    }
    &:hover {
      background: $color-blue;
      color: $color-white;
      .ant-dropdown-menu-title-content {
        > svg {
          > path {
            stroke: $color-white;
          }
        }
      }
    }
  }
}

.ant-dropdown {
  .ant-dropdown-arrow {
    &::before {
      background: #dbdbdb;
    }
  }
}

.drawer-container {
  .ant-drawer-content-wrapper {
    height: unset !important;
    //height: stretch !important;
    min-height: 378px;
    max-height: 100vh;
    .ant-drawer-header {
      border-bottom: none;
      padding-top: 30px;

      .ant-drawer-header-title {
        .ant-drawer-close {
          display: none;
        }

        .ant-drawer-title {
          width: 100%;

          .drawer-title-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .drawer-close-icon {
              cursor: pointer;
            }
          }
        }
      }
    }

    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .drawer-top-wrapper {
        .drawer-list {
          padding: 0;
          margin: 30px 0 42px;
          list-style-type: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 36px;

          .drawer-list-item {
            text-align: center;
            &.highlighted {
              .drawer-list-item-link {
                font-weight: 800;
                color: $color-primary;
              }
            }
            .drawer-list-item-link {
              font-weight: 700;
              font-size: $font-size-xxl;
              font-family: $geo-font-bold;
              color: $color-grey;
              cursor: pointer;
            }
          }
        }

        .drawer-contact-us-button {
          background: $color-green;
          margin-bottom: 40px;
          height: 56px;

          .button-text {
            font-size: $font-size-sm;
          }
        }

        .download-app-wrapper {
          width: 100%;
          margin-bottom: 36px;

          .download-app-text {
            margin-bottom: 16px;
            font-weight: 500;
            font-size: $font-size-sm;
            font-family: $geo-font-medium;
            text-align: center;
            color: $color-grey;
          }

          .download-app-buttons-wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            gap: 10px;

            .download-app-button-wrapper {
              width: 100%;
              .download-app-button {
                background: $color-background;
                padding: 5px 16px;
                height: 48px;

                .button-text {
                  text-transform: uppercase;
                  color: $color-dark;
                  font-weight: 500;
                  font-size: $font-size-xs;
                  font-family: $geo-font-medium;
                }
              }
            }
          }
        }

        .choose-language-wrapper {
          width: 100%;
          margin-bottom: 36px;

          .choose-language-text {
            margin-bottom: 16px;
            font-weight: 500;
            font-size: $font-size-sm;
            font-family: $geo-font-medium;
            text-align: center;
            color: $color-grey;
          }

          .languages-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .language-wrapper {
              display: flex;
              align-items: center;
              gap: 10px;
              cursor: pointer;

              .language-text {
                font-weight: 400;
                font-size: 14px;
                color: #a0acba;
                font-family: $geo-font-secondary;

                &.selected {
                  color: $color-primary;
                }
              }
            }
          }
        }
      }
      .drawer-bottom-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .social-media-icons-wrapper {
          display: flex;
          gap: 12px;
          align-items: center;
        }
        .credits-text {
          margin-top: 5px;
          margin-left: 5px;
          font-weight: 500;
          font-size: $font-size-xs;
          color: $color-grey;
          font-family: $geo-font-medium;
        }
      }
    }
  }
}

@include xl {
  .header-container {
    .header-container-inner {
      .header-left-wrapper {
        gap: 30px;
        .header-menu-wrapper {
          gap: 20px;
        }
      }
      .header-right-wrapper {
        .header-menu-wrapper {
          gap: 25px;
        }
      }
    }
  }
}

@include lg {
  .header-container {
    justify-content: space-between;
    height: 100px;
  }
}

@include sm {
  .drawer-container {
    .ant-drawer-content-wrapper {
      height: stretch !important;
    }
  }
}

@include xs {
  .drawer-container {
    .ant-drawer-content-wrapper {
      height: 100vh !important;
    }
  }
}

@include sm-height {
  .drawer-container {
    .ant-drawer-content-wrapper {
      height: stretch !important;
    }
  }
}

@import "../../../styles/variables.scss";
@import "../../../styles/responsive.scss";

.if-you-want-to-help-container {
  margin-top: 92px;
  margin-bottom: 92px;
  display: flex;
  justify-content: center;
  padding: 0 40px;
  .if-you-want-to-help-wrapper {
    max-width: 1160px;
    flex: 1;
  }
}

@include xl {
  .if-you-want-to-help-container {
    margin-top: 80px;
  }
}
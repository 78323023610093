@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.privacy-policy-modal {
  max-width: 766px !important;
  width: 100% !important;
  .ant-modal-content {
    .ant-modal-body {
      max-height: 65vh;
      padding: 0 40px;
      overflow-y: scroll;
      margin: 20px 20px 20px 0;
      .divider {
        margin-bottom: 10px;
      }
      .custom-ordered-list {
        padding-left: 50px;
        position: relative;
        .custom-ordered-list-item-wrapper {
          .custom-ordered-list-item-count {
            position: absolute;
            left: 0;
          }
        }
      }
    }
    .ant-modal-footer {
      height: 30px;
    }
  }
}

@include sm {
  .privacy-policy-modal {
    &.custom-modal {
      .ant-modal-header {
        padding-bottom: 20px;
      }
    }
    .ant-modal-content {
      .ant-modal-body {
        max-height: 55vh;
        margin-right: 15px;
      }
    }
  }
}

@include sm-height {
  .privacy-policy-modal {
    .ant-modal-content {
      .ant-modal-body {
        max-height: 45vh;
      }
    }
  }
}

@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.authorization-modal {
  width: 556px !important;
  .auth-step-body-container {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    .auth-step-body {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .auth-footer-button-wrapper {
    border-top: 1px solid #f0f0f0;
    padding: 20px 40px 32px;
  }
}

@include lg {
  .authorization-modal {
    .auth-step-body-container {
      height: 372px;
      .auth-step-body {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .auth-footer-button-wrapper {
      border-top: 1px solid #f0f0f0;
      padding: 16px 16px 20px;
    }
  }
}
